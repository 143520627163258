import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import BookVisit from "../components/BookVisit";
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
const IndexPage = () => (
  <Layout
    title="Oops...Ainda não estou EMMIM..."
    description="...porque esta página não existe"
  >
    <Header
      title="Oops...Ainda não estou EMMIM..."
      subtitle="...porque esta página não existe"
    />

    <BookVisit
    />
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
